import Layout from 'components/Layout'
import Head from 'next/head'

export default function Custom404 () {
  return (
    <>
      <Head>
        <title>Sendung verpasst? Die Seite wurde nicht gefunden</title>
      </Head>
      <Layout>
        <h1>Die Seite wurde nicht gefunden</h1>
        <div>
          Die angeforderte Seite konnte nicht gefunden werden.
        </div>
      </Layout>
    </>
  )
}
